import React, { useState, useContext } from 'react';
import { Typography, Container, TextField, Grid, Box, Button, Snackbar, CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { navigate } from '@reach/router';
import { Auth } from "aws-amplify";

import AuthenticationContext from "../../shared/contexts/authenticationContext";
import novionLogoImage from '../../assets/images/novion-logo-wordmark.svg';
import coverImage from '../../assets/images/cover.jpg';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SignIn(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const authContext = useContext(AuthenticationContext);
  const updateCurrentUser = authContext.updateCurrentUser;

  const onChange = (key, value) => {
    setErrorMessage(null);
    if (key === "email") {
      setEmail(value);
    } else if (key === "password") {
      setPassword(value);
    }
  }

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      signIn();
    }
  };

  function signIn() {
    setIsLoading(true);
    Auth.signIn(email.toLowerCase(), password)
    .then(user => {
      console.log(user);
      updateCurrentUser(user);
      if (user.challengeName == null){ 
        updateCurrentUser(user);
        setIsLoading(false);
        navigate("/");
      }
    })
    .catch(err => {
      setIsLoading(false);
      setErrorMessage(err.message);
    });

    // Auth.signUp({
    //   username: email.toLowerCase(),
    //   password,
    //   attributes: {
    //     email: email.toLowerCase(),             
    //     phone_number: '+16043659734',      // E.164 number convention
    //     name: 'Dev',
    //     family_name: 'Bhullar',
    //   },
    // });
  }
  
  return(
    <Grid container direction='row' style={{maxWidth: '98%'}}>
      <Grid item xs={7}>
        <Box height="100vh" display="flex" overflow='hidden'>
          <img src={coverImage} alt="Novion" height='100%'/>
        </Box>
      </Grid>
      <Grid item xs={5}>
        <Box my={4} style={{textAlign:"center", marginTop:"0", paddingTop:"0px"}}>
          <img className="logo" src={novionLogoImage} alt="Resource Managent Platform by Novion" height="300" />
        </Box>
        <Typography style={{marginLeft: 10, marginRight: 10}} variant="h5">Sign in</Typography>
        <form noValidate autoComplete="off">
          <Box style={{marginLeft: 10, marginRight: 10}} my={4}>
            <TextField label="Email " required type="email" fullWidth onChange={event => onChange('email', event.target.value)} margin="normal" size="medium"/>
          </Box>
          <Box style={{marginLeft: 10, marginRight: 10}} my={4}>
            <TextField label="Password " required type="password" fullWidth onChange={event => onChange('password', event.target.value)} onKeyDown={event => handleEnterKey(event)} margin="normal" size="medium"/>
          </Box>
          <Grid style={{marginLeft: 10, marginRight: 10}} container justify="space-between">
            <Grid item>
              <Button variant="contained" onClick={signIn}>Sign In</Button>
              <Box>
                {isLoading && <CircularProgress  size={24} />}
              </Box>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={errorMessage ? true : false} autoHideDuration={2500}>
          <Alert severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}