import React, { useState, useRef, useContext } from 'react';
import { AppBar, Toolbar, Grid, Drawer, List, ListItem, ListItemText, ListItemIcon, IconButton, Popper, Grow, ClickAwayListener, Paper, MenuList, MenuItem, Typography, Avatar, makeStyles } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { Auth } from "aws-amplify";

import AuthenticationContext from "../../shared/contexts/authenticationContext";
import novionLogoImage from '../../assets/images/novion-logo-white-wordmark.png';

export default function Navigator(props) {
  const authContext = useContext(AuthenticationContext);
  const updateCurrentUser = authContext.updateCurrentUser;
  
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div style={{flexGrow: 1}}>
      <AppBar>
        <Toolbar>
          <Grid style={{marginLeft: '75px', marginRight: '75px'}} container alignItems='center' justifyContent='space-between'>
            <img src={novionLogoImage} alt="Novion" height="40" />
            <Typography >Climate Intelligence Platform</Typography>
            <IconButton edge="end" onClick={handleToggle}>
              <Avatar ref={anchorRef}></Avatar>
              <ArrowDropDown style={{color: 'white'}}/>
            </IconButton>
          </Grid>
          <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom"
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow">
                        <MenuItem 
                          onClick={() => {
                            Auth.signOut()
                            .catch((err) => console.log('Auth signout error: ', err))
                            .then((data) => {
                              console.log('signout worked', data);
                              updateCurrentUser(null);
                              // navigate('/sign-in');
                            });
                            }
                          }
                        >
                          Sign Out
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
        </Toolbar>
      </AppBar>
      {/* <Drawer
        style={{marginTop: APPBAR_HEIGHT, width: DRAWER_WIDTH}}
        classes={{
          paper: classes.drawerPaper
        }}
        variant="permanent"
        anchor="left"
      >
        <div/>
        <List>
          {drawerList.map((text, index) => (
            <ListItem button key={text} onClick={() => handleActiveDrawerItemChange(text)}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer> */}
    </div>
  );
}
