export const accessRecordUserByUser = /* GraphQL */ `
  query AccessRecordUserByUser(
    $userID: ID
  ) {
    accessRecordUserByUser(
      userID: $userID
    ) {
      items {
        accessRecord {
          accessRecordLoggers {
            items {
              logger {
                id
                siteName
                createdAt
                serialNumber
                siteLatitudeDegrees
                siteLongitudeDegrees
                updatedAt
                events(limit: 1, sortDirection: DESC) {
                  items {
                    batteryLevelPercent
                    batteryVoltageV
                    createdAt
                    eventName
                    eventTimeStamp
                    id
                    latitudeDegrees
                    loggerID
                    longitudeDegrees
                    updatedAt
                  }
                }
                sensors {
                  items {
                    id
                    loggerID
                    name
                    serialNumber
                    type
                    updatedAt
                    events(limit: 1, sortDirection: DESC) {
                      items {
                        createdAt
                        eventName
                        eventTimeStamp
                        id
                        reading
                        sensorID
                        supplyVoltage
                        temperatureC
                        updatedAt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const sensorEventBySensor = /* GraphQL */ `
  query SensorEventBySensor(
    $sensorID: ID
    $eventTimeStamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSensorEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sensorEventBySensor(
      sensorID: $sensorID
      eventTimeStamp: $eventTimeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sensorID
        eventName
        eventTimeStamp
        reading
        temperatureC
        supplyVoltage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
