import React from 'react';
import { Typography, IconButton, InputLabel, Grid, Box } from '@material-ui/core';
import { navigate, useLocation } from "@reach/router";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Navigator from '../../../shared/components/navigator.js';

export default function SettingsScreen(props) {
  const location = useLocation();

  return (
    <div style={{flexGrow: 1, marginLeft: '200px', marginTop: '110px', marginRight: '100px',}}>
      <Navigator currentScreen="GRI Type 1"/>
      <Grid container direction='row'>
        <Grid item xs='2'>
          <IconButton
            onClick={() => navigate('/')}
          >
            <ArrowBackIcon fontSize='large' />
          </IconButton>
        </Grid>
        <Grid item xs='10'>
          <form noValidate autoComplete="off">
            <Box my={4}>
              <InputLabel shrink>
                Site Name
              </InputLabel>
              <Typography variant="h4">{location.state.siteName}</Typography>
            </Box>
            <Box my={4}>
              <InputLabel shrink>
                Logger ID
              </InputLabel>
              <Typography variant="h4">{location.state.loggerID}</Typography>
            </Box>
            <Box my={4}>
              <InputLabel shrink>
                Sensor Model
              </InputLabel>
              <Typography variant="h4">{location.state.sensorModel}</Typography>
            </Box>
            <Box my={4}>
              <InputLabel shrink>
                Battery Level
              </InputLabel>
              <Typography variant="h4">{location.state.sensorModel === 'YF-B7' ? 'N/A' : location.state.batteryLevel}</Typography>
            </Box>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}