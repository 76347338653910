import React from 'react';
import { Typography, Grid } from '@material-ui/core';

export default function Footer(props) {
  const currentYear = new Date(Date.now()).getFullYear();

  return (
    <Grid container style={{marginTop: 50}} justify="center" alignItems="center">
      <Typography /* variant="h4" */>{`© ${currentYear} Novion Inc. All Rights Reserved`}</Typography>
    </Grid>
  );
}