import React, { useState, useEffect } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';

import Navigator from '../../shared/components/navigator';
import Footer from '../../shared/components/footer';

import ResourcesMap from './components/resources-map.js';
import ResourcesTable from './components/resources-table.js';
import DownloadModal from './components/download-modal.js';
import { getAllLoggersForUser } from '../../shared/api-calls/logger-api-calls';
import { mapLoggerItemToResource } from '../../shared/utils/maps';
import { Auth } from 'aws-amplify';

export default function ResourcesOverview (props) {
  const [ loading, setLoading ] = useState(false);
  const [ openDownloadModal, setOpenDownloadModal] = useState(false);
  const [ downloadSensorID, setDownloadSensorID] = useState(null);
  const [ downloadSiteName, setDownloadSiteName] = useState();
  const [ resources, setResources] = useState([]);
  const [ overviewSiteName, setOverviewSiteName] = useState('Resource Monitoring Platform');

  useEffect(() => {
    setLoading(true);

    Auth.currentAuthenticatedUser()
    .then(user => {
      getAllLoggersForUser(user.attributes.email)
      .then(loggerItems => {
        let resourceDetails = [];
        loggerItems.forEach(loggerItem => {
          resourceDetails.push(mapLoggerItemToResource(loggerItem));
        })

        setResources(resourceDetails);

        if (user.attributes.email.includes('ubc.ca')) {
          setOverviewSiteName('University of British Columbia - Vancouver Campus');
        }
        else if (user.attributes.email.includes('newwestcity.ca')) {
          setOverviewSiteName('City of New Westminster');
        }
        else {
          setOverviewSiteName('City of Vancouver GRI Monitoring');
        }
        setLoading(false);
      })
    })
  }, [])

  function handleDownloadModalClose() {
    setOpenDownloadModal(false);
  }

  return (
    <div style={{flexGrow: 1, marginLeft: '100px', marginTop: '100px', marginRight: '100px',}}>
      <Navigator currentScreen="GRI Type 1" />
      <Grid container style={{marginBottom: '10px'}}>
        <Typography variant='h4'>{overviewSiteName}</Typography>
        <Button size='small' disabled style={{border: 'solid thin black', marginLeft: 'auto', color: 'black'}}>
          {resources.length} Loggers
        </Button>
        <Button size='small' disabled style={{border: 'solid thin black', marginLeft: '20px', color: 'black'}}>
          {(resources.filter(resource => resource.online)).length} Online
        </Button>
      </Grid>
      {
        loading ? null :
        <React.Fragment>
          <div style={{marginBottom: '20px'}}>
            <ResourcesMap resources={resources} />
          </div>
          <ResourcesTable
            resources={resources}
            setOpenDownloadModal={setOpenDownloadModal}
            setDownloadSensorID={setDownloadSensorID}
            setDownloadSiteName={setDownloadSiteName}
          />
        </React.Fragment>
      }
      <DownloadModal siteName={downloadSiteName} sensorID={downloadSensorID} open={openDownloadModal} onClose={handleDownloadModalClose}/>
      <Footer />
    </div>
  );
}
