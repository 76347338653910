import React from 'react';

import { Router, LocationProvider, Redirect } from '@reach/router';

import SignIn from './Authentication/sign-in.js';
import ResourcesOverview from './Resources/resources-overview.js'
import ResourceDetails from './Resources/components/resource-details.js';
import AuthenticationContext, { AuthConsumer } from '../shared/contexts/authenticationContext';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return( 
    <AuthConsumer>
      {
        (value) => {
          console.log("ProtectedRoute user: ", value.user);
          // logger.debug("Redirecting from component: ", Component);
          return (value.user ? <Component {...rest} /> : <Redirect from="" to="/sign-in" noThrow/>);
        }
      }
    </AuthConsumer>
  );
};

const PublicRoute = ({ component: Component, ...rest }) => (
  <Component {...rest} />
);

export default function AppRouter(props) {
  return(
    <LocationProvider>
      <Router>
        <PublicRoute path="/sign-in" component={SignIn}/>
        <ProtectedRoute path="/" component={ResourcesOverview}/>
        <ProtectedRoute path="/resource" component={ResourceDetails}/>
      </Router>
    </LocationProvider>
  );
}