import { getLiters } from "./get-liters";

export function mapLoggerItemToResource(loggerItem) {
  let resource = {
    online: null,
    name: null,
    loggerID: null,
    sensor: null,
    sensorID: null,
    latestValue: null,
    timestamp: null,
    sensorModel: null,
    batteryLevel: null,
    latitude: null,
    longitude: null,
  };

  resource.name = loggerItem.siteName;
  resource.loggerID = loggerItem.serialNumber;
  resource.sensor = loggerItem.sensors.items[0].type;
  resource.sensorID = loggerItem.sensors.items[0].id;
  resource.latestValue = (loggerItem.sensors.items[0].events.items[0].reading * 0.704944599).toFixed(3);
  resource.timestamp = new Date(loggerItem.sensors.items[0].events.items[0].eventTimeStamp).toLocaleString();
  resource.sensorModel = loggerItem.sensors.items[0].name;
  resource.batteryLevel = getBatteryLevel(loggerItem.events.items[0].batteryVoltageV)/* batteryLevelPercent */;
  resource.latitude = loggerItem.siteLatitudeDegrees;
  resource.longitude = loggerItem.siteLongitudeDegrees;
  resource.online = (
    new Date(Date.now()).getTime() - 
    new Date(loggerItem.sensors.items[0].events.items[0].eventTimeStamp).getTime()
  ) < 14400000; // 14400000 ms = 4 hours

  if (resource.sensor === 'Water Flow Sensor') {
    resource.latestValue = getLiters (
      loggerItem.sensors.items[0].events.items[0].eventTimeStamp,
      loggerItem.sensors.items[0].events.items[0].reading
    ).toFixed(3);
  }

  return resource;
}

function getBatteryLevel(batteryVoltageV) {
  let batteryLevel = 'n/a';

  if (batteryVoltageV > 3.9) {
    batteryLevel = 'High';
  }
  else if ( (batteryVoltageV <= 3.9) && (batteryVoltageV >= 3.6) ) {
    batteryLevel = 'Medium';
  }
  else if ( batteryVoltageV < 3.6 ) {
    batteryLevel = 'Low';
  }
  
  return batteryLevel;
}
