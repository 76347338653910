import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Dialog, Grid, Button, CircularProgress, Chip, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJS from '@date-io/dayjs';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';

import ResourceChart from './resource-chart';
import { getSensorEventSeries } from '../../../shared/api-calls/sensor-api-calls';
import { apiCallCreateSensorEventDownloadRequest } from '../../../shared/api-calls/download-request-api-calls';
import { getLiters } from '../../../shared/utils/get-liters';

export default function DownloadModal(props) {
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [chartData, setChartData] = useState([]);
  const [activeChip, setActiveChip] = useState(24);
  
  function saveFile() {
    const startDateTime = startDate;
    startDateTime.setHours(0, 0, 0, 0);
    const endDateTime = endDate;
    endDateTime.setHours(23, 59, 59, 999);

    let sensorEventsToDownload = chartData.map(chartDataItem => {
      const sensorEventItemDate = chartDataItem.x;
      const sensorEventItemReading = chartDataItem.y;
      return `${sensorEventItemDate.toLocaleDateString()} ${sensorEventItemDate.toLocaleTimeString()},${sensorEventItemReading}\r\n`;
    });

    if (props.sensorID === '8') {
      sensorEventsToDownload.unshift('Timestamp, Volume [L]\r\n');
    }
    else {
      sensorEventsToDownload.unshift('Timestamp, Water Level [m]\r\n');
    }
      
    const blob = new Blob(sensorEventsToDownload, {type: "text/plain;charset=utf-8"});
    const dateString = `${startDate.toDateString()} to ${endDate.toDateString()}`;
    const fileName = `${props.siteName} - ${dateString}.csv`;
    FileSaver.saveAs(blob, fileName);

    Auth.currentAuthenticatedUser()
    .then(user => {
      const downloadRequest = {
        sensorID: props.sensorID,
        email: user.attributes.email,
        startTime: startDateTime.toISOString(),
        endTime: endDateTime.toISOString(),
        timeZone: 'America/Vancouver',
        siteName: props.siteName,
      };
  
      apiCallCreateSensorEventDownloadRequest(downloadRequest)
      .catch(e => {
        console.log("error in createFileDownloadRequest", e);
      })
    })
  }

  useEffect(() => {
    if (props.sensorID != null) {
      setLoading(true);
      const startDateTime = startDate;
      startDateTime.setHours(0, 0, 0, 0);
      const endDateTime = endDate;
      endDateTime.setHours(23, 59, 59, 999);

      getSensorEventSeries(props.sensorID, startDateTime.toISOString(), endDateTime.toISOString())
      .then((sensorEventItems) => {
        // remove items that are zeros (invalid pressure sensor data)
        sensorEventItems = sensorEventItems.filter (sensorEventItem => {
          return parseFloat(sensorEventItem.reading) !== 0;
        });

        sensorEventItems = sensorEventItems.map (sensorEventItem => {
          const timeStampDate = new Date(sensorEventItem.eventTimeStamp);
          const timeStampMinuteMod = timeStampDate.getMinutes() % 5;
          const timeStampSeconds = timeStampDate.getSeconds();
          if (timeStampMinuteMod > 2 || (timeStampMinuteMod === 2 && timeStampSeconds >= 30)) {
            // round up
            timeStampDate.setMinutes(timeStampDate.getMinutes() - timeStampMinuteMod + 5);
            timeStampDate.setSeconds(0);
          } else {
            // round down
            timeStampDate.setMinutes(timeStampDate.getMinutes() - timeStampMinuteMod);
            timeStampDate.setSeconds(0);
          }
          
          if (props.sensorID === '8') {
            return `${timeStampDate.toISOString()},${getLiters(sensorEventItem.eventTimeStamp, sensorEventItem.reading).toFixed(3)}\r\n`;
          }
          else {
            return `${timeStampDate.toISOString()},${(sensorEventItem.reading * 0.704944599).toFixed(3)}\r\n`;
          }
        });

        let index = 0;
        while (index < (sensorEventItems.length - 1)) {
          const currentSensorEventDate = new Date(sensorEventItems[index].split(',')[0]);
          const nextSensorEventDate = new Date(sensorEventItems[index + 1].split(',')[0]);

          const currentSensorEventReading = Number(sensorEventItems[index].split(',')[1]);
          const nextSensorEventReading = Number(sensorEventItems[index + 1].split(',')[1]);

          if ( (nextSensorEventDate.getTime() - currentSensorEventDate.getTime()) > 300000 ) {
            let interpolatedDate = currentSensorEventDate;
            interpolatedDate.setMinutes(currentSensorEventDate.getMinutes() + 5);
            const interpolatedReading = ((currentSensorEventReading + nextSensorEventReading) / 2).toFixed(3);
            const interpolatedSensorEvent = `${interpolatedDate.toISOString()},${interpolatedReading}\r\n`;
            
            sensorEventItems.splice(index + 1, 0, interpolatedSensorEvent);
          }

          if ( nextSensorEventDate.getTime() === currentSensorEventDate.getTime() ) {
            sensorEventItems.splice(index, 1);
            index--;
          }

          index++;
        }

        // keeping the timestamps in ISO format until now so that all time comparisons above are valid.
        sensorEventItems = sensorEventItems.map (sensorEventItem => {
          const sensorEventItemDate = new Date(sensorEventItem.split(',')[0]);
          const sensorEventItemReading = sensorEventItem.split(',')[1];
          return { x: sensorEventItemDate, y: parseFloat(sensorEventItemReading) };
        });

        setChartData(sensorEventItems);
        setLoading(false);
      });
    }
  }, [startDate, endDate, props.sensorID])

  function handle24ChipClick() {
    setActiveChip(24);
    setStartDate(new Date());
    setEndDate(new Date());
  }

  function handle72ChipClick() {
    setActiveChip(72);
    let startDate72 = new Date();
    startDate72.setDate(startDate72.getDate() - 2);
    setStartDate(startDate72);
    setEndDate(new Date());
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => {props.onClose()}}
        aria-labelledby="download"
        aria-describedby="download logger data over specified period"
      >
        <Typography align='center' variant='h5' style={{marginTop: 10}}>
          {props.siteName}
        </Typography>
        <Grid container direction='row' wrap='nowrap'>
          <Grid item>
            <Grid container direction='column' style={{marginLeft: 15}}>
              <Grid container direction='row' justifyContent='center' style={{marginTop: 40}}>
                <Chip label={'Today'} clickable color='primary' style={{marginRight: 5}} onClick={handle24ChipClick} variant={activeChip === 24 ? 'default' : 'outlined'} />
                <Chip label={'3 days'} clickable color='primary' style={{marginLeft: 5}} onClick={handle72ChipClick} variant={activeChip === 72 ? 'default' : 'outlined'} />
              </Grid>
              <MuiPickersUtilsProvider utils={DayJS} >
                <KeyboardDatePicker
                  disableToolbar
                  autoOk 
                  // fullWidth
                  // className="datePicker"  
                  // variant="inline"
                  disableFuture
                  minDate = {dayjs('2022-02-15T08:00:00.000Z')}
                  InputProps={{disableUnderline: true}}
                  format = "MMM D , YYYY"
                  // keyboardIcon={
                  //   <Icon >
                  //     <ExpandMoreIcon style={{color: "#FFFFFF"}} height="20px" />
                  //   </Icon>
                  // }
                  inputstyle={{ textAlign: 'center'}}
                  value={startDate} 
                  // required
                  style={{maxWidth: 180, marginLeft: 20, marginRight: 20}}
                  margin="normal" 
                  size="medium"
                  onChange={(date) => {setStartDate(date.toDate()); setActiveChip(null);}}
                  label={'Start Date'}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DayJS} >
                <KeyboardDatePicker
                  disableToolbar
                  autoOk 
                  // fullWidth
                  // className="datePicker"  
                  // variant="inline"
                  disableFuture
                  minDate = {dayjs(startDate)}
                  InputProps={{disableUnderline: true}}
                  format = "MMM D , YYYY"
                  // keyboardIcon={
                  //   <Icon >
                  //     <ExpandMoreIcon style={{color: "#FFFFFF"}} height="20px" />
                  //   </Icon>
                  // }
                  inputstyle={{ textAlign: 'center'}}
                  value={endDate} 
                  // required
                  style={{maxWidth: 180, marginLeft: 20, marginRight: 20}}
                  margin="normal" 
                  size="medium"
                  onChange={(date) => {setEndDate(date.toDate()); setActiveChip(null);}}
                  label={'End Date'}
                />
              </MuiPickersUtilsProvider>
              <Button color={'primary'} variant={'outlined'} disabled={loading} onClick={() => saveFile()} style={{borderRadius: 20}}>Download</Button>
            </Grid>
          </Grid>
          <Grid item style={{height: 280}}>
            <Grid container direction='column' style={{width: 400}}>
              {
                loading ? <CircularProgress style={{margin: 'auto', marginTop: '30%'}}/> :
                <ResourceChart chartData={chartData} unitLabel={props.sensorID === '8' ? 'liter' : 'meter'} />
              }
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
