import React from 'react';
import { VictoryChart, VictoryTheme, VictoryAxis, VictoryLine } from 'victory';

export default function ResourceChart(props) {
  return (
    <VictoryChart
      scale={{ x: "time", y: "linear" }}
      theme={VictoryTheme.grayscale}
      padding={{ left: 90, top: 50, right: 50, bottom: 50 }}
    >
      <VictoryAxis
        fixLabelOverlap
      />
      <VictoryAxis
        dependentAxis
        label={props.unitLabel}
        style={{axisLabel: {padding: 70}}}
        domain={[0, 0.5]}
      />
      <VictoryLine
        style={{
          data: { stroke: '#5e96f4' },
          parent: { border: '1px solid #ccc'}
        }}
        data={props.chartData}
      />
    </VictoryChart>
  );
}
